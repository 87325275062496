import React from "react"
import Layout from "../components/layout"
import Latest from "../components/blog/latest"
import Hero from "../components/home/hero"
import divider1 from "../assets/home/divider1.png"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"

const BlogTemplate = props => {
  React.useEffect(() => {
    document.body.scrollTop = 0 // For Safari
    document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
  }, [])

  if (!props.data) return null
  const blogData = props.data.allPrismicBlogPageSingle.edges[0].node.dataRaw
  // data sources
  const title = blogData.title[0].text
  const description = blogData.metadescription
    ? blogData.metadescription[0].text
    : ""
  const hero = props.data.allPrismicUniversalBlock1.edges[0].node.data
  const heroSection = {
    src: hero.home_page_hero_banner,
  }

  return (
    <div>
      <Helmet>
        <meta name="description" content={description} />
        <title>{title}</title>
      </Helmet>
      <Layout>
        <Hero data={heroSection}  bannerImage1={null} bannerImage2={null}/>
        <img src={divider1} style={{ width: "100%" }} alt="" />
        <Latest data={blogData} type="blog" />
      </Layout>
    </div>
  )
}

export const queryBlog = graphql`
  query blogPost($id: String!) {
    allPrismicBlogPageSingle(filter: { id: { eq: $id } }) {
      edges {
        node {
          uid
          id
          dataRaw
        }
      }
    }
    allPrismicUniversalBlock1 {
      edges {
        node {
          data {
            home_page_hero_banner {
              url
            }
          }
        }
      }
    }
  }
`

export default BlogTemplate
